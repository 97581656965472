import styled from "@emotion/styled";
export const StyledTimerVerticalTabsMobileComponent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 40px;

  .mobile-tabs-title-wrapper {
    margin-bottom: 48px;
  }

  .mobile-button-link {
    padding: 16px 0;
  }
`;
