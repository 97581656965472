import styled from "@emotion/styled";
export const StyledVerticalTimerTabMobileComponent = styled.div`
  padding-bottom: 16px;

  .vertical-timer-tab-mobile-title {
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 22px;
    font-weight: 300;
    font-size: 0.875rem;
    padding: 0;

    .vertical-timer-tab-mobile-title-badge-margin {
      display: inline;
      margin-left: 8px;
    }

    .vertical-timer-tab-arrow-wrapper {
      flex-grow: 1;
      text-align: end;

      .vertical-timer-tab-arrow {
        width: 22px;
        transition: 0.3s transform ease-in-out;

        &.selected {
          transform: rotate(180deg);
        }
      }
    }
  }

  .vertical-timer-tab-mobile-title-selected {
    font-style: normal;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 22px;
    padding: 0 0 16px 0;
  }

  .vertical-timer-tab-mobile-progress-container {
    margin-bottom: 16px;
  }

  .vertical-timer-tab-mobile-content-container {
    height: 0;
    opacity: 0;
    transition: opacity 0.3s linear;

    .vertical-timer-tab-mobile-content {
      padding-bottom: 16px;
      line-height: 22px;
      font-weight: 300;
      font-size: 0.875rem;
    }

    .vertical-timer-tab-mobile-asset {
      padding: 10px;
    }
  }

  .vertical-timer-tab-mobile-content-container-selected {
    height: 100%;
    opacity: 1;
  }
`;
