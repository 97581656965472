import styled from "@emotion/styled";
import { MD } from "constants/vp-sizes";
const mdScreenSize = MD + "px";
export const StyledVerticalTimerTabs = styled.div`
  .desktop-wrapper {
    display: block;
  }

  .mobile-wrapper {
    display: none;
  }

  @media (max-width: ${mdScreenSize}) {
    .desktop-wrapper {
      display: none;
    }

    .mobile-wrapper {
      display: block;
    }
  }
`;
